import React from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "./App.scss";
// Import custom components
// import Redirect from "./utils/Redirect";
import { routes } from "./utils/Routes";
import Home from "./pages/HomePage";
import DashBoard from "./pages/DashBoard";
import JoinSession from "./components/JoinSession/JoinSession";
import "react-modal-video/css/modal-video.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./components/About/About";
import NavTopnav from "./components/NavTopnav/NavTopnav";
import AcademicYear from "./components/Layout/Administrator/AcademicYear";
import Academic from "./components/Layout/Academic/Academic";
import SchoolSection from "./components/Layout/Academic/SchoolSection";
import SchoolSubject from "./components/Layout/Academic/SchoolSubject";
import Designation from "./components/Layout/HumanResource/Designation";
import Department from "./components/Layout/HumanResource/Department";
import Employee from "./components/Layout/HumanResource/Employee";
import AddEmployee from "./components/Layout/HumanResource/AddEmployee";
import ExamGrade from "./components/Layout/Exam/ExamGrade";
import UserRole from "./components/Layout/Administrator/UserRole";
import ExamTerm from "./components/Layout/Exam/ExamTerm";
import ExamSchedule from "./components/Layout/Exam/ExamSchedule";
import HallTicket from "./components/Layout/Exam/HallTicket";
import AdmitStudent from "./components/Layout/Student/AdmitStudent";
import AddAdmitStudent from "./components/Layout/Student/AddAdmitStudent";
import ExamAttendance from "./components/Layout/ExamMark/ExamAttendance";
import QuickAdmit from "./components/Layout/Student/QuickAdmit";
import Reshuffle from "./components/Layout/Student/Reshuffle";
import Promotion from "./components/Layout/Student/Promotion";
import Settings from "./components/Layout/Settings/Settings";
import AnnounceNews from "./components/Layout/Announcement/AnnounceNews";
import AnnounceHoliday from "./components/Layout/Announcement/AnnounceHoliday";
import Notice from "./components/Layout/Announcement/Notice";
import ClassRoutine from "./components/Layout/ClassRoutine/ClassRoutine";
import ManageMark from "./components/Layout/ExamMark/ManageMark";
import ExamMark from "./components/Layout/ExamMark/ExamResult";
import StudyCertificate from "./components/Layout/Certificate/StudyCertificate";
import EditStudent from "./components/Layout/Student/EditStudent";
import EditEmployee from "./components/Layout/HumanResource/EditEmployee";
import TransferCertificate from "./components/Layout/Certificate/TransferCertificate";
import MarksCard from "./components/Layout/ExamMark/MarksCard";
import Compose from "./components/Layout/Communication/Compose";
import Report from "./components/Layout/Communication/Report";
import TextMessage from "./components/Layout/Communication/TextMessage";
import RolePermission from "./components/Layout/Administrator/RolePermission";
import AdminRolePermission from "./components/Layout/Administrator/AdminRolePermission";
import AssignRolePermission from "./components/Layout/Administrator/AssignRolePermission";
import StudentAttendance from "./components/Layout/Attendance/StudentAttendance";
import EmployeeAttendance from "./components/Layout/Attendance/EmployeeAttendance";
import Sidebar1 from "./components/Navigation/components/Sidebar1";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import dataSidebar from "./components/Navigation/components/DataSidebar";
import { NavLink } from "react-router-dom";
import Consolidated from "./components/Layout/ExamMark/Consolidated";
import NewRolePermission from "./components/Layout/Administrator/NewRolePermission";
import { urls } from "./services/urlConstant";
import { get } from "./services/apis";
import StudentTable from "./components/Layout/Student/StudentTable";
import Login from "./components/Layout/Authentication/Login";
import ResetPassword from "./components/Layout/ResetPassword/ResetPassword";
import { objHas } from "./utils/utils";
import Event from "./components/Layout/Events/Event";
import ForgotPassword from "./components/Layout/ForgotPassword";
import BulkAdmission from "./components/Layout/Student/BulkAdmission";
import NavBar from "./NavBar";
import PrivateRoute from "./PrivateRoute";
import ResetPasswordUser from "./components/Layout/ResetPassword/ResetPasswordUser";
import Addmission from "./components/Addmission";
import Academics from "./components/Academics";
import Primary from "./components/Primary";
import HighSchool from "./components/HighSchool";
import Library from "./components/Library";
import Sports from "./components/Sports";
import Canteen from "./components/Canteen";
import Transport from "./components/Transport";
import EduSmart from "./components/EduSmart";
import Assignment from "./components/Layout/Assignment/Assignment";
import ContactUs from "./components/ContactUs";
import PublicRoute from "./PublicRoute";
import Results from "./components/Results";
import Gallery from "./components/Layout/Gallery/gallery";
import GalleryHome from "./components/GalleryHome";
import OurSchool from "./components/Our school/OurSchool";
import Principal from "./components/PrincipalDesk/Principal";
import Faculty from "./components/Faculty/Faculty";
import SettingContext from "./context/SettingsContext";
import EventHome from "./components/EventHome";
import UniqueFeatures from "./components/UniqueFeatures";
import KnowledgeOfParents from "./components/KnowledgeOfParents";
import Careers from "./components/Careers";
import HomeNews from "./components/HomeNews";
import HomeNewsView from "./components/HomeNewsView";
import HomeNotice from "./components/HomeNotice";
import HomeNoticeView from "./components/HomeNoticeView";
import EmployeeView from "./components/Layout/HumanResource/EmployeeView";
import AssignmentHome from "./components/AssignmentHome";
import AssignmentView from "./components/AssignmentView";
import Credential from "./components/Layout/Student/Credential";
import Birthday from "./components/Layout/Birthday/Birthday";
import Awards from "./components/Layout/Awards/Awards";
import AwardsAndAchivments from "./components/AwardsAndAchivments";
import Overview from "./components/Layout/Student/Overview";
import LatestEventsById from "./components/LatestEventsById";
import StudentBulkPhoto from "./components/Layout/Student/StudentBulkPhoto";
import Toppers from "./components/toppers";
import ReceiptBook from "./components/Layout/Fees/ReceiptBook";
import QuickFee from "./components/Layout/Fees/QuickFee";
import QuickFeeCollection from "./components/Layout/Fees/QuickFeeCollection";
import FeeCategoryList from "./components/Layout/Fees/FeeCategoryList";
import FeeRemittance from "./components/Layout/Fees/FeeRemittance";
import DetailedFeeCollection from "./components/Layout/Fees/DetailedFeeCollection";
import BalanceFeeReport from "./components/Layout/Fees/balanceFeeReport";
import FeeOverview from "./components/Layout/Fees/feeOverview";
import Credentails from "./components/Layout/Communication/Credentials";
import StudentDashbord from "./pages/StudentDashbord";
import Hostel from "./components/Hostel/Hostel";
import ManageRoom from "./components/Hostel/ManageRoom";
import ManageRoomType from "./components/Hostel/ManageRoomType";
import ManageRoomFee from "./components/Hostel/ManageRoomFee";
import HostelMember from "./components/Hostel/HostelMember";
import StudentLibrary from "./components/StudentLibrary/StudentLibrary";
import Periodical from "./components/StudentLibrary/Periodical";
import StudentMember from "./components/StudentLibrary/StudentMember";
import StudentIssue from "./components/StudentLibrary/StudentIssue";
import EmployeeMember from "./components/StudentLibrary/EmployeeMember";
import EmployeeIssue from "./components/StudentLibrary/EmployeeIssue";
import Vehicle from "./components/Transport/Vehicle";
import ManageRoute from "./components/Transport/ManageRoute";
import Trips from "./components/Transport/Trips";
import TransportMember from "./components/Transport/TransportMember";
import VehicleLog from "./components/Transport/VehicleLog";
import VehicleMaintenance from "./components/Transport/VehicleMaintenance";
import ManageStopFee from "./components/Transport/ManageStopFee";
import SplashNews from "./components/Layout/Announcement/SplashNews";
import SalaryGrade from "./components/Layout/Payroll/SalaryGrade";
import SalaryPayment from "./components/Layout/Payroll/SalaryPayment";
import VisitoryInfo from "./components/VisitoryInfo/VisitoryInfo";
import StudentCheckOut from "./components/VisitoryInfo/StudentCheckOut";
import NavbarProfile from "./components/Layout/NavbarProfile/NavbarProfile";
import PasswordReset from "./components/Layout/NavbarProfile/PasswordReset";
import Logout from "./components/Layout/NavbarProfile/Logout";
import StudentActivity from "./components/Layout/Student/StudentActivity";
import SubjectWiseReport from "./components/Layout/ExamMark/SubjectWiseReport";
import DivisionwiseReport from "./components/Layout/ExamMark/DivisionwiseReport";

import Storage from "./components/Layout/Lms/Storage";
import Live from "./components/Layout/Lms/Live/LiveSession";
import AddCourse from "./components/Layout/Lms/Course/AddCourse";
import Products from "./components/Layout/Lms/Course/Products";
import UploadCourse from "./components/Layout/Lms/UploadCourse/UploadCourse";

import Room from "./components/Layout/Lms/Live/Room";
import GuardianFeedback from "./components/Layout/GuardianFeedback/GuardianFeedback";
import AddFeedback from "./components/Feedbacks/AddFeedback";

import ProgressReport from "./components/Layout/ExamMark/ProgressReport";
import AluminiFeedback from "./components/AluminiFeedack/AluminiFeedback";
import AddAluminiFeedack from "./components/AluminiFeedack/AddFeedback";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndCondition from "./components/TermsAndCondition";
import SectionWiseReport from "./components/Layout/ExamMark/SectionWiseReport";
import VacationAdmissionForm from "./components/VacationAdmission/VacationAdmissionForm";
import VacationAdmission from "./components/VacationAdmission/VacationAdmission";
import FinalReport from "./components/Layout/ExamMark/FinalReport";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function App() {
  let updateNav = false;
  const token = window.localStorage.getItem("token");
  if (token) updateNav = true;
  const [reRender, setRender] = useState(1);
  const [showDrawerHeader, setShowDrawerHeader] = useState(true);

  const location = useLocation();

  const [settingsContext, setSettingsContext] = useState({
    schoolName: "",
    schoolLogo: "",
    activeAcademicYear: "",
  });

  useEffect(() => {
    if (location.pathname == "/app") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=exce.com.ecampusstreet";
    }
  }, [location.pathname]);

  useEffect(() => {
    const { pathname } = location;
    const excludePath = [
      "/home-assignment",
      "/home-notice",
      `/home-news`,
      "/home-news",
      "/home-careers",
      "/privacy-policy",
      "/terms-conditions",
      "/guardian-feedback",
      "/to-the-knowledge-of-parents",
      "/unique-features",
      "/home-gallery",
      "/results",
      "/contact-us",
      "/alumni-feedback",
      "/facilities/edusmart",
      "/facilities/transport",
      "/",
      "/login",
      "/forgot-password",
      "/principal",
      "/desk",
      "/facality",
      "/addmission",
      "/our-school",
      "/guardian-feedback",
      "/academics",
      "/academics/primary",
      "/academics/high-school",
      "/facilities/library",
      "/facilities/sports",
      "/facilities/canteen",
      "/academics/vacation-admission-form",
    ];
    const exclude =
      excludePath.includes(pathname) ||
      pathname.startsWith("/home-news") ||
      pathname.startsWith("/home-notice") ||
      pathname.startsWith("/view-assignment") ||
      pathname.startsWith("/awards-home") ||
      pathname.startsWith("/events-home") ||
      pathname.startsWith("/toppers-list");
    setShowDrawerHeader(!exclude);
  }, [location.pathname]);

  const triggerRender = () => {
    console.log("cbsdjbc");
    setRender((prev) => prev + 1);
  };

  return (
    <SettingContext.Provider
      value={{
        settingsContext,
        setSettingsContext,
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#F5F5F5",
          height: "100vh",
          position: "fixed",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <CssBaseline />
        {updateNav && <NavBar reRender={reRender} />}
        <div className="App">
          <Box component="main">
            {showDrawerHeader && <DrawerHeader />}
            <StylesProvider injectFirst>
              <Routes>
                <Route path="/" element={<NavTopnav />} exact>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                </Route>
                <Route
                  path="/login"
                  element={
                    <PublicRoute>
                      <Login />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/dashboard/sd"
                  element={
                    <PrivateRoute>
                      <StudentDashbord />
                    </PrivateRoute>
                  }
                />
                {/* <Route path="/dashboard/sdd" element={
                    <PrivateRoute>
                      <Calendar/>  
                    </PrivateRoute>
                  }>

                </Route> */}
                <Route path="/moreproject" element={<JoinSession />} exact />
                <Route
                  path={routes.dashBoard}
                  element={
                    <PrivateRoute>
                      <DashBoard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/academicyear"
                  element={
                    <PrivateRoute>
                      <AcademicYear />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/class"
                  element={
                    <PrivateRoute>
                      <Academic />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/section"
                  element={
                    <PrivateRoute>
                      <SchoolSection />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/subject"
                  element={
                    <PrivateRoute>
                      <SchoolSubject />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/gaurdianfeednack"
                  element={
                    <PrivateRoute>
                      <GuardianFeedback />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/designation"
                  element={
                    <PrivateRoute>
                      <Designation />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/department"
                  element={
                    <PrivateRoute>
                      <Department />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/employee"
                  element={
                    <PrivateRoute>
                      <Employee />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/addemployee"
                  element={
                    <PrivateRoute>
                      <AddEmployee />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/examgrade"
                  element={
                    <PrivateRoute>
                      <ExamGrade />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/role"
                  element={
                    <PrivateRoute>
                      <UserRole />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/room/:roomID"
                  element={
                    <PrivateRoute>
                      <Room />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/examterm"
                  element={
                    <PrivateRoute>
                      <ExamTerm />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/examschedule"
                  element={
                    <PrivateRoute>
                      <ExamSchedule />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/subjectwisereport"
                  element={
                    <PrivateRoute>
                      <SubjectWiseReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/sectionWiseReport"
                  element={
                    <PrivateRoute>
                      <SectionWiseReport />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/divissiowisereport"
                  element={
                    <PrivateRoute>
                      <DivisionwiseReport />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/final_report"
                  element={
                    <PrivateRoute>
                      <FinalReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/progress_Report"
                  element={
                    <PrivateRoute>
                      <ProgressReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/addCourse"
                  element={
                    <PrivateRoute>
                      <AddCourse />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/course-content"
                  element={
                    <PrivateRoute>
                      <UploadCourse />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/live"
                  element={
                    <PrivateRoute>
                      <Live />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/storage"
                  element={
                    <PrivateRoute>
                      <Storage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/course"
                  element={
                    <PrivateRoute>
                      <Products />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/exam/hallticket"
                  element={
                    <PrivateRoute>
                      <HallTicket />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admitstudent"
                  element={
                    <PrivateRoute>
                      <StudentTable />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="/quickAdmit"
                  element={
                    <PrivateRoute>
                      <QuickAdmit />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path="/addstudent"
                  element={
                    <PrivateRoute>
                      <AddAdmitStudent />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/examattendance"
                  element={
                    <PrivateRoute>
                      <ExamAttendance />
                    </PrivateRoute>
                  }
                />
                <Route path="/desk" element={<Faculty />} />
                <Route
                  path="/managemark"
                  element={
                    <PrivateRoute>
                      <ManageMark />
                    </PrivateRoute>
                  }
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/event" element={<Event />} />
                <Route path="/principal" element={<Principal />} />
                <Route
                  path="/examresult"
                  element={
                    <PrivateRoute>
                      <ExamMark />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/reshuffle"
                  element={
                    <PrivateRoute>
                      <Reshuffle />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/quickAdmit"
                  element={
                    <PrivateRoute>
                      <QuickAdmit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/promotion"
                  element={
                    <PrivateRoute>
                      <Promotion />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bulkadmission"
                  element={
                    <PrivateRoute>
                      <BulkAdmission />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/announcenews"
                  element={
                    <PrivateRoute>
                      <AnnounceNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/announceholiday"
                  element={
                    <PrivateRoute>
                      <AnnounceHoliday />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/notice"
                  element={
                    <PrivateRoute>
                      <Notice />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/splashNews"
                  element={
                    <PrivateRoute>
                      <SplashNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="routine"
                  element={
                    <PrivateRoute>
                      <ClassRoutine />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/studycertificate"
                  element={
                    <PrivateRoute>
                      <StudyCertificate />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/edit-student/:studentId"
                  element={
                    <PrivateRoute>
                      <EditStudent />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/edit-employee/:employeeId"
                  element={
                    <PrivateRoute>
                      <EditEmployee />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/transfercertificate"
                  element={
                    <PrivateRoute>
                      <TransferCertificate />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/marks-card"
                  element={
                    <PrivateRoute>
                      <MarksCard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/compose"
                  element={
                    <PrivateRoute>
                      <Compose />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/sms-report"
                  element={
                    <PrivateRoute>
                      <Report />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/text-message"
                  element={
                    <PrivateRoute>
                      <TextMessage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/role-permission"
                  element={
                    <PrivateRoute>
                      <RolePermission />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/role-permission/:id"
                  element={
                    <PrivateRoute>
                      <AssignRolePermission />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin-role-permission"
                  element={
                    <PrivateRoute>
                      <AdminRolePermission />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student-attendance"
                  element={
                    <PrivateRoute>
                      <StudentAttendance />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/employee-attendance"
                  element={
                    <PrivateRoute>
                      <EmployeeAttendance />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/sidebar-1"
                  element={
                    <PrivateRoute>
                      <Sidebar1 />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/consolidated"
                  element={
                    <PrivateRoute>
                      <Consolidated />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/new-role-permission"
                  element={
                    <PrivateRoute>
                      <NewRolePermission />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/receipt"
                  element={
                    <PrivateRoute>
                      <ReceiptBook />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/quick-fee"
                  element={
                    <PrivateRoute>
                      <QuickFee />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/quick-fee-collection"
                  element={
                    <PrivateRoute>
                      <QuickFeeCollection />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/fee-remittance"
                  element={
                    <PrivateRoute>
                      <FeeRemittance />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/detailed-fee"
                  element={
                    <PrivateRoute>
                      <DetailedFeeCollection />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/feecategorylist"
                  element={
                    <PrivateRoute>
                      <FeeCategoryList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/balancefeereport"
                  element={
                    <PrivateRoute>
                      <BalanceFeeReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/feeoverview"
                  element={
                    <PrivateRoute>
                      <FeeOverview />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <PrivateRoute>
                      <ResetPassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/event"
                  element={
                    <PrivateRoute>
                      <Event />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/hostel"
                  element={
                    <PrivateRoute>
                      <Hostel />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/hostelroom"
                  element={
                    <PrivateRoute>
                      <ManageRoom />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/hostelroomtype"
                  element={
                    <PrivateRoute>
                      <ManageRoomType />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/hostelroomfee"
                  element={
                    <PrivateRoute>
                      <ManageRoomFee />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/hostelmember"
                  element={
                    <PrivateRoute>
                      <HostelMember />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/librarybooks"
                  element={
                    <PrivateRoute>
                      <StudentLibrary />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/libraryperiodical"
                  element={
                    <PrivateRoute>
                      <Periodical />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/studentlibrarymember"
                  element={
                    <PrivateRoute>
                      <StudentMember />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/studentissue"
                  element={
                    <PrivateRoute>
                      <StudentIssue />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/employeelibrary"
                  element={
                    <PrivateRoute>
                      <EmployeeMember />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/employeeissue"
                  element={
                    <PrivateRoute>
                      <EmployeeIssue />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/vehicle"
                  element={
                    <PrivateRoute>
                      <Vehicle />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/route"
                  element={
                    <PrivateRoute>
                      <ManageRoute />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/trips"
                  element={
                    <PrivateRoute>
                      <Trips />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/transportmember"
                  element={
                    <PrivateRoute>
                      <TransportMember />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/log"
                  element={
                    <PrivateRoute>
                      <VehicleLog />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/maintenance"
                  element={
                    <PrivateRoute>
                      <VehicleMaintenance />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/stop"
                  element={
                    <PrivateRoute>
                      <ManageStopFee />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/visitoryinfo"
                  element={
                    <PrivateRoute>
                      <VisitoryInfo />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/studentcheckout"
                  element={
                    <PrivateRoute>
                      <StudentCheckOut />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="/dashboard/category"
                  element={
                    <PrivateRoute>
                      <Category />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/vendor"
                  element={
                    <PrivateRoute>
                      <Vendor />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/item"
                  element={
                    <PrivateRoute>
                      <Item />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/itemInventory"
                  element={
                    <PrivateRoute>
                      <ItemInventory />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/issueDetails"
                  element={
                    <PrivateRoute>
                      <IssueDetails />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path="/dashboard/grade"
                  element={
                    <PrivateRoute>
                      <SalaryGrade />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard/payment"
                  element={
                    <PrivateRoute>
                      <SalaryPayment />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="/dashboard/histroy"
                  element={
                    <PrivateRoute>
                    <PaymentHistroy />
                    </PrivateRoute>
                  }
                />  */}
                {/* <Route
                  path="/dashboard/studentYearlyAttendence"
                  element={
                    <PrivateRoute>
                      <StudentYearlyAttendence />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/teacherAttendence"
                  element={
                    <PrivateRoute>
                      <TeacherAttendence />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/studentAttendence"
                  element={
                    <PrivateRoute>
                      <StudentAttendenceReport />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/teacherYearlyAttendence"
                  element={
                    <PrivateRoute>
                      <TeacherYearlyAttendence />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/teacherYearlyAttendence"
                  element={
                    <PrivateRoute>
                      <TeacherYearlyAttendence />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/employeeAttendence"
                  element={
                    <PrivateRoute>
                      <EmployeeAttendence />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/employeeYearlyAttendence"
                  element={
                    <PrivateRoute>
                      <EmployeeYearlyAttendence />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/studentReport"
                  element={
                    <PrivateRoute>
                      <StudentReport />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/studentAcivityReport"
                  element={
                    <PrivateRoute>
                      <StudentActivityReport />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/accountDivision"
                  element={
                    <PrivateRoute>
                      <AccountDivision />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/receiptBook"
                  element={
                    <PrivateRoute>
                      <ReceiptBookAccount />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/accountVendor"
                  element={
                    <PrivateRoute>
                      <VendorAccount />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/incomeHead"
                  element={
                    <PrivateRoute>
                      <IncomeHead />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/income"
                  element={
                    <PrivateRoute>
                      <Income />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/expenditureHead"
                  element={
                    <PrivateRoute>
                      <ExpenditureHead />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/dashboard/expenditure"
                  element={
                    <PrivateRoute>
                      <Expenditure />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/preAdmissionExam"
                  element={
                    <PrivateRoute>
                      <Preadmission />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/preAdmissionListEnquiry"
                  element={
                    <PrivateRoute>
                      <ListEnquiry />
                    </PrivateRoute>
                  }
                /> */}
                {/* <Route
                  path="/helpDesk"
                  element={
                    <PrivateRoute>
                      <HelpDesk />
                    </PrivateRoute>
                  }
                /> */}
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <NavbarProfile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/resetPassword"
                  element={
                    <PrivateRoute>
                      <PasswordReset />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/logOut"
                  element={
                    <PrivateRoute>
                      <Logout />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bulkadmission"
                  element={
                    <PrivateRoute>
                      <BulkAdmission />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/reset-password-user"
                  element={
                    <PrivateRoute>
                      <ResetPasswordUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/assignment"
                  element={
                    <PrivateRoute>
                      <Assignment />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/alumini-feedback"
                  element={
                    <PrivateRoute>
                      <AluminiFeedback />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/vacation-admission"
                  element={
                    <PrivateRoute>
                      <VacationAdmission />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile-view/:employeeId"
                  element={
                    <PrivateRoute>
                      <EmployeeView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student-credential"
                  element={
                    <PrivateRoute>
                      <Credential />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student-activity"
                  element={
                    <PrivateRoute>
                      <StudentActivity />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/awards"
                  element={
                    <PrivateRoute>
                      <Awards />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/birthday"
                  element={
                    <PrivateRoute>
                      <Birthday />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/student-bulk-photo"
                  element={
                    <PrivateRoute>
                      <StudentBulkPhoto />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/send-credentials"
                  element={
                    <PrivateRoute>
                      <Credentails />
                    </PrivateRoute>
                  }
                />
                <Route path="/addmission" element={<Addmission />} />
                <Route path="/academics" element={<Academics />} />
                <Route path="/academics/primary" element={<Primary />} />
                <Route
                  path="/academics/vacation-admission-form"
                  element={<VacationAdmissionForm />}
                />
                <Route path="/academics/high-school" element={<HighSchool />} />
                <Route path="/student-overview" element={<Overview />} />
                <Route path="/facilities/library" element={<Library />} />
                <Route path="/facilities/sports" element={<Sports />} />
                <Route path="/facilities/canteen" element={<Canteen />} />
                <Route path="/facilities/transport" element={<Transport />} />
                <Route path="/facilities/edusmart" element={<EduSmart />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/results" element={<Results />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/home-gallery" element={<GalleryHome />} />
                <Route path="/our-school" element={<OurSchool />} />
                <Route path="/principal" element={<Principal />} />
                <Route path="/facality" element={<Faculty />} />
                <Route path="/text-message" element={<TextMessage />} />
                <Route
                  path="/dashboard/gaurdianfeednack"
                  element={<GuardianFeedback />}
                />
                <Route path="/event-home" element={<EventHome />} />
                <Route path="/unique-features" element={<UniqueFeatures />} />
                <Route
                  path="/to-the-knowledge-of-parents"
                  element={<KnowledgeOfParents />}
                />
                <Route path="/home-careers" element={<Careers />} />
                <Route path="/guardian-feedback" element={<AddFeedback />} />
                <Route
                  path="/alumni-feedback"
                  element={<AddAluminiFeedack />}
                />
                <Route path="/home-news" element={<HomeNews />} />
                <Route path="/home-news/:newsId" element={<HomeNewsView />} />
                <Route path="/home-notice" element={<HomeNotice />} />
                <Route
                  path="/home-notice/:noticeId"
                  element={<HomeNoticeView />}
                />
                <Route path="/home-assignment" element={<AssignmentHome />} />
                <Route path="/view-assignment" element={<AssignmentView />} />
                <Route
                  path="/awards-home/:awardsId"
                  element={<AwardsAndAchivments />}
                />
                <Route
                  path="/events-home/:eventsId"
                  element={<LatestEventsById />}
                />
                <Route path="/toppers-list" element={<Toppers />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-conditions"
                  element={<TermsAndCondition />}
                />
              </Routes>
            </StylesProvider>
          </Box>
          <ToastContainer />
        </div>
      </Box>
    </SettingContext.Provider>
  );
}

export default App;
