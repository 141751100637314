import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Paper } from "@mui/material";
import { get } from "../../../services/apis";
import { urls } from "../../../services/urlConstant";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import CustomSelect from "../../CustomSelect";
import { instance } from "../../../services/axios.Config";

export default function FinalReport() {
  const [classes, setClasses] = useState([]);

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    getClasses();
  }, []);

  const getClasses = async () => {
    try {
      const { data } = await get(urls.class.getAllClass);
      setClasses(
        data.data.map((c) => ({ ...c, label: c.className, value: c._id }))
      );
      formik.setFieldValue("class", data.data[0]?._id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadReport = async (values) => {
    if (!values.class) return alert("Please select class and section");
    try {
      setDownloading(true);

      const getFinalReport = await instance({
        method: "get",
        url: `/exam-result/final-result`,
        params: {
          classId: values.class,
        },
        responseType: "blob",
      });
      if (!getFinalReport) return setDownloading(false);
      const uri = URL.createObjectURL(getFinalReport.data);
      const link = document.createElement("a");
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setDownloading(false);
  };

  const formik = useFormik({
    initialValues: {
      class: "",
    },
    onSubmit: handleDownloadReport,
  });

  return (
    <>
      <Card style={{ margin: 15, padding: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mg={12} lg={12}>
            <span
              style={{
                color: "red",
                fontFamily: "cursive",
                textAlign: "start",
              }}
            >
              Final Report:
            </span>
          </Grid>
        </Grid>
      </Card>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          columnGap: "10px",
          padding: "10px",
          margin: "10px",
          borderRadius: "5px",
        }}
        component={Paper}
      >
        <CustomSelect
          formik={formik}
          label="Select Class"
          name="class"
          options={classes}
          required={true}
        />

        <LoadingButton
          disabled={!formik.values.class}
          loading={downloading}
          onClick={formik.handleSubmit}
          variant="contained"
          size="small"
          sx={{
            background: "#1b3779",
            ":hover": { background: "#1b3779" },
          }}
        >
          Download
        </LoadingButton>
      </Box>
    </>
  );
}
