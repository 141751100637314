import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { urls } from "../../../services/urlConstant";
import { post, get } from "../../../services/apis";
import ReactToPrint, { useReactToPrint } from "react-to-print";

const styles = {
  card: {
    padding: 10,
    width: "100%",

    marginTop: "10px",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
    m: 1,
  },
  Button: {
    background: "#1b3779",
    ":hover": { background: "#1b3779" },
  },

  outerCard: {
    padding: "20px",
  },
  innerCard: {
    margin: "10px",
    padding: "20px",

    textAlign: "center",
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function SectionWiseRseport() {
  const [graphData, setGraphData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [examTerms, setExamTerms] = useState([]);
  const [subjectWiseReport, setSubjectWiseReport] = useState({});
  const [selectedClassName, setSelectedClassName] = useState("");
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await get(urls.class.getAllClass);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getClasses();
  }, []);

  useEffect(() => {
    const getExamTerms = async () => {
      try {
        const res = await get(urls.examTerm.getAllExamTerm);
        setExamTerms(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getExamTerms();
  }, []);

  const handleClasses = async (e) => {
    try {
      const { name, value } = e.target;
      setSubjectWiseReport({ ...subjectWiseReport, [name]: value });

      setSelectedClassName(
        classes.find((c) => c._id === value)?.className || ""
      );

      const subject = await get(urls.schoolSubject.getAllSubject, {
        params: {
          search: {
            class: value,
          },
        },
      });
      setSubjects(subject.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExamTerms = (e) => {
    const { name, value } = e.target;
    setSubjectWiseReport({ ...subjectWiseReport, [name]: value });
  };

  const subjectWiseReportHandler = async (e) => {
    e.preventDefault();

    try {
      const res = await post(`${urls.sectionwisegraph.create}`, {
        classId: subjectWiseReport.className,
        examTermIds: subjectWiseReport.examTitle,
        subjectId: subjectWiseReport.subjectIds,
      });

      setGraphData(res.data.transformedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubjects = (e) => {
    const { name, value } = e.target;
    setSubjectWiseReport({ ...subjectWiseReport, [name]: value });
  };

  //

  return (
    <Container padding={2}>
      <Card style={styles.card}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item sx={12} md={12} lg={12}>
              <span
                style={{
                  color: "red",
                  fontFamily: "cursive",
                }}
              >
                Subject Wise Report :
              </span>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <form onSubmit={subjectWiseReportHandler}>
        <Card style={styles.card}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel sx={{ fontSize: 12 }}>Class</InputLabel>
                  <Select
                    name="className"
                    value={subjectWiseReport.className || ""}
                    onChange={handleClasses}
                  >
                    {classes.map((row) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.className}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel sx={{ fontSize: 12 }}>Subject</InputLabel>
                  <Select
                    name="subjectIds"
                    value={subjectWiseReport.subjectIds || ""}
                    onChange={handleSubjects}
                  >
                    {subjects.map((row) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.subjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl variant="standard" sx={styles.textfield} required>
                  <InputLabel sx={{ fontSize: 12 }}>Exam</InputLabel>
                  <Select
                    name="examTitle"
                    multiple
                    value={subjectWiseReport.examTitle || []}
                    onChange={handleExamTerms}
                  >
                    {examTerms.map((row) => (
                      <MenuItem
                        key={row._id}
                        value={row._id}
                        sx={{ fontSize: 12, fontWeight: 500 }}
                      >
                        {row.examTitle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  size="small"
                  style={styles.Button}
                  type="submit"
                >
                  Find
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </form>
      {/* <div>
        {graphData.map((graph, graphIndex) => {
          const transformedData = graph.labels.map((label, index) => ({
            name: label,
            value: graph.datasets[0].data[index],
          }));

          return (
            <Card style={styles.card} key={graphIndex}>
              <Typography variant="h6" gutterBottom>
                {graph.datasets[0].label}
              </Typography>
              <BarChart
                width={500}
                height={300}
                data={transformedData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="value"
                  fill={graph.datasets[0].backgroundColor}
                  stroke={graph.datasets[0].borderColor}
                />
              </BarChart>
            </Card>
          );
        })}
      </div> */}

      {graphData.length > 0 ? (
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
                marginTop: "20px",
                background: "#1b3779",
                ":hover": { background: "#1b3779" },
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      ) : null}
      {graphData.length > 0 && (
        <Paper
          elevation={0}
          sx={{ zoom: 0.8 }}
          ref={componentRef}
          style={styles.outerCard}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", textAlign: "center", mb: 2, mt: 1 }}
          >
            {selectedClassName} Section-Wise Report
          </Typography>
          <Grid container spacing={2} style={styles.chartContainer}>
            {graphData.map((graph, graphIndex) => {
              const transformedData = graph.labels.map((label, index) => ({
                name: label,
                marks: graph.datasets[0].data[index],
              }));

              return (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  key={graphIndex}
                >
                  <Card sx={{ zoom: 0.8 }} style={styles.innerCard}>
                    <Typography variant="h6" gutterBottom>
                      {graph.datasets[0].label}
                    </Typography>
                    <div style={{ width: "100%" }}>
                      <BarChart width={500} height={600} data={transformedData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="marks"
                          fill={graph.datasets[0].backgroundColor}
                          stroke={graph.datasets[0].borderColor}
                        />
                      </BarChart>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      )}
    </Container>
  );
}
